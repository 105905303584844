import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ensureReady, After, getSerializedData } from '@jaredpalmer/after';
import './client.css';
import routes from './routes';
import configureStore from './store/configureStore';

const preloadedState = getSerializedData('preloaded_state');
const store = configureStore(preloadedState);

const Main = ({ data }) => {
  // useEffect(() => {
  //   const jssStyles = document.querySelector('#jss-server-side');
  //   if (jssStyles) {
  //     jssStyles.parentElement.removeChild(jssStyles);
  //   }
  // }, []);

  return (
    <Provider store={store}>
        {/* <CssBaseline /> */}
      <BrowserRouter>
        <After data={data} routes={routes} store={store} />
      </BrowserRouter>{' '}
    </Provider>
  );
};

const renderApp = () => {
  ensureReady(routes).then(data => {
    return hydrate(<Main data={data} />, document.getElementById('root'));
  });
};

renderApp();

if (module.hot) {
  module.hot.accept('./routes', renderApp);
}
