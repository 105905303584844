import getAxiosInstance from "../config/http";

export const LOGIN_REQUEST = "USER@LOGIN_REQUEST";
export const LOGIN_SUCCESS = "USER@LOGIN_SUCCESS";
export const LOGIN_FAILURE = "USER@LOGIN_FAILURE";

export const login = (userData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LOGIN_REQUEST,
  });
  try {
    const { data } = await http.post("/auth/local-simple", userData);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        userData: data,
      },
    });
    http.defaults.headers.common["Authorization"] = `bearer ${data.jwt}`;
    return data;
  } catch (e) {
    // console.log(e);
    dispatch({
      type: LOGIN_FAILURE,
    });
    throw e;
  }
};

export const REGISTER_REQUEST = "USER@REGISTER_REQUEST";
export const REGISTER_SUCCESS = "USER@REGISTER_SUCCESS";
export const REGISTER_FAILURE = "USER@REGISTER_FAILURE";

export const register = (userData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: REGISTER_REQUEST,
  });
  try {
    const { data } = await http.post("/auth/local-simple/register", userData);
    dispatch({
      type: REGISTER_SUCCESS,
      payload: {
        userData: data,
      },
    });
    return data;
  } catch (e) {
    // console.log(e);
    dispatch({
      type: REGISTER_FAILURE,
    });
  }
};
