import * as GameSessionActions from "../actions/gameSession";
import reducerWithActionMap from "../utils/reducerWithActionMap";

const initialState = {
  currentSession: null,
};

const updateSession = (state, { payload: { sessionData } }) => ({
  ...state,
  currentSession: sessionData,
});

const actionMap = {
  [GameSessionActions.CREATE_SESSION_SUCCESS]: updateSession,
  [GameSessionActions.SLOT_MACHINE_ROLL]: updateSession,
};

export default reducerWithActionMap(actionMap, initialState);
