import _remove from 'lodash/remove';
import * as UserActions from '../actions/user';
import reducerWithActionMap from "../utils/reducerWithActionMap";

const initialState = {
  isAuthenticated: false,
  // usersList: {
  //   isFetching: false,
  //   isFetched: false,
  //   listData: [],
  //   total: 0,
  // },
  userDetails: {
    isFetching: false,
    isFetched: false,
    userData: {},
  },
};

const setUserListFetching = fetching => state => ({
  ...state,
  usersList: {
    ...state.usersList,
    isFetching: fetching,
  },
});

const setUserList = (state, { payload: { listData, total } }) => ({
  ...state,
  usersList: {
    ...state.usersList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

// const setUserFetching = fetching => state => ({
//   ...state,
//   userDetails: {
//     ...state.userDetails,
//     isFetching: fetching,
//   },
// });

const setUser = (state, { payload: { userData } }) => ({
  ...state,
  userDetails: {
    ...state.userDetails,
    userData,
    isFetching: false,
    isFetched: true,
  },
});

const setUserDeleted = (state, { payload: { userId } }) => {
  const newListData = state.usersList.listData;
  _remove(newListData, { id: userId });

  const newState = {
    ...state,
    usersList: {
      ...state.usersList,
      listData: newListData,
    },
  };
  return newState;
};

const actionMap = {
  // [UserActions.LIST_USER_REQUEST]: setUserListFetching(true),
  // [UserActions.LIST_USER_SUCCESS]: setUserList,
  // [UserActions.LIST_USER_FAILURE]: setUserListFetching(false),
  // [UserActions.FETCH_USER_REQUEST]: setUserFetching(true),
  // [UserActions.FETCH_USER_SUCCESS]: setUser,
  // [UserActions.FETCH_USER_FAILURE]: setUserFetching(false),
  [UserActions.LOGIN_SUCCESS]: setUser,
};

export default reducerWithActionMap(actionMap, initialState);


