import getAxiosInstance from "../config/http";

export const CREATE_SESSION_REQUEST = "GAME_SESSION@CREATE_REQUEST";
export const CREATE_SESSION_SUCCESS = "GAME_SESSION@CREATE_SUCCESS";
export const CREATE_SESSION_FAILURE = "GAME_SESSION@CREATE_FAILURE";

export const createSession = (requestData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_SESSION_REQUEST,
  });
  try {
    const { data } = await http.post("/game-sessions", requestData);
    dispatch({
      type: CREATE_SESSION_SUCCESS,
      payload: {
        sessionData: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: CREATE_SESSION_FAILURE,
    });
    throw e;
  }
};

export const SLOT_MACHINE_ROLL = "GAME_SESSION@SLOT_MACHINE_ROLL";

export const slotMachineRoll = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  // dispatch({
  //   type: CREATE_SESSION_REQUEST,
  // });
  try {
    const { data } = await http.post(
      "/merkurbet/slotMachine/roll",
      {},
      { params }
    );
    dispatch({
      type: SLOT_MACHINE_ROLL,
      payload: {
        sessionData: data,
      },
    });
    return data;
  } catch (e) {
    // dispatch({
    //   type: CREATE_SESSION_FAILURE,
    // });
    throw e;
  }
};
