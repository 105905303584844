import * as ModalActions from "actions/modal";
import reducerWithActionMap from '../utils/reducerWithActionMap';

const initialState = {};

const openModal = (state, { payload: {id, data}}) => ({
  ...state,
  [id]: data,
});

const closeModal = (state, {payload: {id}}) => {
  delete state[id];
  return {
    ...state,
  }
};

const actionMap = {
  [ModalActions.OPEN_MODAL]: openModal,
  [ModalActions.CLOSE_MODAL]: closeModal,
};

export default reducerWithActionMap(actionMap, initialState);
