import axios from 'axios';

const defaultConfig = {
  baseURL: process.env.RAZZLE_API_URL,
  timeout: 5000,
  withCredentials: true,
};

var instance = null;

export const initializeAxios = config => {
  instance = axios.create(Object.assign(defaultConfig, config));
};

const getAxiosInstance = () => {
  if (!instance) {
    initializeAxios();
  }
  return instance;
};

export default getAxiosInstance;
