import React from 'react';

import { asyncComponent } from '@jaredpalmer/after';

export default [
  {
    path: '/',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/Homepage'),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: '/slotMachine',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/SlotMachine'),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  // {
  //   path: '/bravo',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: () => import('./pages/Bravo'),
  //     Placeholder: () => <div>...LOADING...</div>,
  //   }),
  // },
  {
    path: '/playedAlready',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/PlayedAlready'),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: '/alreadyHaveAccount',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/AlreadyHaveAccount'),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: '/noBonus',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/NoBonus'),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: '/terms',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/Terms'),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: '/userGuide',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/UserGuide'),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
];
